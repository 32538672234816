import '../../styles/pages/spring-summer-2024/a-titan-raises-the-bar.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2024/a-titan-raises-the-bar/a-titan-raises-the-bar-hero-1920x1080.jpg';
import socialBanner from "../../images/spring-summer-2024/social-banners/a-titan-raises-the-bar-social-banners-1200x630.jpg";
import titelGraphic from "../../images/spring-summer-2024/a-titan-raises-the-bar/a-titan-raises-the-bar-title-380x245.svg";
import ctaBanner from '../../images/spring-summer-2024/a-titan-raises-the-bar/a-titan-raises-the-bar-cta-banner-1920x515.jpg';

import exampleImage1 from '../../images/spring-summer-2024/example-story/example-image-1.jpg';
import exampleImage2 from '../../images/spring-summer-2024/example-story/example-image-2.jpg';
import exampleImage3 from '../../images/spring-summer-2024/example-story/example-image-3.jpg';
import exampleImage4 from '../../images/spring-summer-2024/example-story/example-image-4.jpg';
import exampleImage5 from '../../images/spring-summer-2024/example-story/example-image-5.jpg';
import exampleImage8 from '../../images/spring-summer-2024/example-story/example-image-8.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'CSUF Alumnus and Attorney Represents Google, Apple Inc.';
    var pageDesc = 'Cal State Fullerton political science and communications alumnus Gregory Washington is an attorney, representing such clients as Google, Uber and Apple Inc.';
    var slug = 'a-titan-raises-the-bar';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: exampleImage1,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: exampleImage2,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: exampleImage3,
        width: 800,
        height: 553,
        alt: '',
        title: ''
      },
      {
        src: exampleImage4,
        width: 800,
        height: 553,
        alt: '',
        title: ''
      }
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='A Titan Raises the Bar' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Alumnus Gregory Washington competed on Cal State Fullerton’s moot court team. Now he’s an attorney who has represented clients like Google, PG&E, Uber and Apple Inc. 
</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey / Photo courtesy of Gregory Washington</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>



          <p><span className="intro-text">If you  ask Gregory Washington&rsquo;s parents</span> why he pursued a career as an attorney, they&rsquo;ll tell you that it&rsquo;s because he loved debating everything when he was growing up.</p>
<p>But Washington adds that his love for law and policy landed him in a courtroom.</p>
<p>After graduating from Cal State Fullerton, Washington &rsquo;12 (B.A. communication studies, political science) earned his law degree from UC Berkeley. Today, he is an attorney at Jenner &amp; Block in San Francisco, providing legal counsel to business clients across the country.</p>
<p>A proud Titan, Washington shares how Cal State Fullerton helped him go from competing with the moot court team to representing clients like Google and Apple Inc. in state and federal courts.</p>

<h3>Can you describe a day in the life of an attorney?</h3>

<p>From contract disputes to thorny intellectual property issues, I help companies navigate ever-changing legal landscapes and make arguments on their behalf in state and federal courts. The core responsibility of an attorney is to act as an adviser for clients, helping them make the best possible decisions and serving as their advocate in court when needed.</p>

<h3>What&rsquo;s one of your favorite moments as a lawyer?</h3>

<p>During my first year of practice, I helped a pro bono client get asylum in front of an immigration judge who only granted asylum in a small percentage of cases. In that moment, you could see years of uncertainty about his immigration status give way to pure elation as my client realized he now had a home. To this day, it&rsquo;s my favorite moment as a lawyer.</p>

<h3>How did CSUF prepare you to succeed in your career?</h3>

<p>Being part of Cal State Fullerton&rsquo;s <a href="https://mootcourt.fullerton.edu/" title="Moot court" target="_blank">moot court</a> team was easily the biggest influence for my legal career in college. Moot court is a simulation of the United States Supreme Court where students study case records and present their arguments to a panel of attorneys, law faculty and occasionally, members of the government&rsquo;s judicial branch. Moot court was my first opportunity to explore legal writing and oral advocacy. The program taught me how to analyze and understand legal issues before delivering persuasive arguments &mdash; much like I do now. I do not think I would be where I am today without Professor of Political Science Pamela Fiber-Ostrow&rsquo;s encouragement and my moot court experiences.</p>

<h3>What inspired you to take an active role in the Alumni Association?</h3>

<p>Since I graduated from CSUF, I have lived in Sacramento, Los Angeles, New Haven and the Bay Area, but I&rsquo;ve always taken my college years with me. Choosing to attend CSUF is one of the best decisions I ever made. During the pandemic, I thought deeply about what my years at CSUF meant to me, and I wanted to find a way to give back to my Titan community. That&rsquo;s when I decided to serve on the <a href="https://alumni.fullerton.edu/" title="Alumni Association" target="_blank">Alumni Association</a> board of directors. As a board member, I have the opportunity to connect with fellow alumni and plan programs and events that benefit our graduates.</p>

<h3>What advice would you give to students who want to pursue careers in law?</h3>

<p>Always remember the reason you wanted to become a lawyer. From law school to passing the California bar exam and figuring out how to practice law in a professional setting, the journey is sometimes difficult. If you can hold on to what made you want to pursue a legal career in the first place, you will be able to overcome any challenges that come your way. I also had to learn to confront my imposter syndrome. In law school and early in my career, I was always worried that I was not good enough. Though I had the resume of someone who should be in every room I was in, I never truly felt like I belonged. Once I embraced what made me different, my career began to take off. <span className="end-mark" /></p>
	
	
	
	

</div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support CSUF Moot Court</span><br />
              <a href="https://give.fullerton.edu/mootcourt" target='_blank' rel='noreferrer'>give.fullerton.edu/mootcourt</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 